import styled from 'styled-components';

export const Verts = styled.div`

border-left: rgb(211,211,211) 1px solid;
height: 100%;
top: 1%;
position: absolute;
left: 25%;
display: inline-block;
`